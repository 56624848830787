import React from "react";
import Button from "./Button";
import RightArrowSmBlue from "../assets/right-arrow-sm-blue.svg";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import styled from "styled-components";
import { useIntl } from "react-intl";
import ScanIcon from "../assets/scanIcon.svg";
import SuccessGif from "../assets/success.gif";
import { abortScanRequest } from "../redux/Api";

const Background = styled.div`
  background: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalInner = styled.div`
  border-radius: 18px;
  background: #fff;
  position: relative;
  max-width: 40vw;
  padding: 50px;
  z-index: 9999;
`;

const HeaderText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #464e5f;
  margin: 10px 0 24px 0;
`;

const ModalText = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #80808f;
  margin: 20px 0;
  padding-left: 16px;
  & > ol > li > b {
    font-size: 16px;
    font-weight: 600;
    color: #212121;
  }
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
`;

const ErrorText = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
`;

const SuccessContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function MrzScanModal({ closeModal, handleScan, scanStatus }) {
  const intl = useIntl();

  return (
    <Background>
      <ModalInner>
        {scanStatus?.isSuccess ? (
          <>
            <SuccessContainer>
              <img src={SuccessGif} alt="scan-icon" width={300} height={300} />
            </SuccessContainer>
            <HeaderText style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "YOUR_DOCUMENT_SUCCESFULLY_SCANNED" })}
            </HeaderText>
          </>
        ) : (
          <>
            <img src={ScanIcon} alt="scan-icon" style={{ width: "100%" }} />
            <HeaderText>
              {intl.formatMessage({ id: "MRZ_SCAN_HEADER" })}
            </HeaderText>
            <ModalText>
              <ol>
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "MRZ_MODAL_1" }),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "MRZ_MODAL_2" }),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "MRZ_MODAL_3" }),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "MRZ_MODAL_4" }),
                  }}
                />
                <li
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: "MRZ_MODAL_5" }),
                  }}
                />
              </ol>
            </ModalText>
            {scanStatus?.isMRZerror ? (
              <ErrorText>
                {
                  // intl.formatMessage({ id: "MRZ_ERROR" })
                  intl.formatMessage({
                    id:
                      "ERROR_MRZ_" +
                      scanStatus?.errorNumber +
                      "_" +
                      scanStatus?.errorCode,
                  })
                }
              </ErrorText>
            ) : scanStatus?.isCantReaded ? (
              <ErrorText>
                {intl.formatMessage({ id: "MRZ_CANT_READ" })}
              </ErrorText>
            ) : null}
            <ButtonContainer>
              <Button
                isSolid
                onClick={() => {
                  abortScanRequest();
                  closeModal();
                }}
              >
                {intl.formatMessage({ id: "CLOSE" })}
                <ArrowImg src={RightArrowSmBlue} alt="right-arrow" />
              </Button>
              <Button
                onClick={handleScan}
                disabled={scanStatus?.isScanning}
                style={{ marginLeft: 16 }}
              >
                {intl.formatMessage({
                  id: scanStatus?.isScanning
                    ? "SCANNING"
                    : !scanStatus?.isFirstScanStarted
                    ? "SCAN"
                    : "SCAN_AGAIN",
                })}
                <ArrowImg src={RightArrowSm} alt="right-arrow" />
              </Button>
            </ButtonContainer>
          </>
        )}
      </ModalInner>
    </Background>
  );
}

export default MrzScanModal;
