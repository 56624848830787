import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const MRZ_SCAN_URL = process.env.REACT_APP_MRZ_SCAN_URL;

const AUTH_LOGIN = `${API_URL}/auth/login`;
const MRZ_SCAN = `${MRZ_SCAN_URL}/?action=mrzRead&simulate=1`;
export const REFRESH_TOKEN_URL = `${API_URL}/auth/refresh`;
const VISITOR_MANAGEMENT = `${API_URL}/visitor-management`;

export const authLogin = (username, password) => {
  return axios.post(AUTH_LOGIN, { email: username, password });
};

export const visitorManagement = (
  documentType,
  name,
  documentNumber,
  birthDate,
  documentExpiryDate,
  phonenumber,
  companyName,
  expiry
) => {
  return axios.post(VISITOR_MANAGEMENT, {
    documentType: documentType,
    name,
    documentNumber,
    birthDate: `${new Date(birthDate).getFullYear()}-${
      new Date(birthDate).getMonth() + 1 < 10
        ? "0" + (new Date(birthDate).getMonth() + 1)
        : new Date(birthDate).getMonth() + 1
    }-${
      new Date(birthDate).getDate() < 10
        ? "0" + new Date(birthDate).getDate()
        : new Date(birthDate).getDate()
    }`,
    documentExpiryDate: `${new Date(documentExpiryDate).getFullYear()}-${
      new Date(documentExpiryDate).getMonth() + 1 < 10
        ? "0" + (new Date(documentExpiryDate).getMonth() + 1)
        : new Date(documentExpiryDate).getMonth() + 1
    }-${
      new Date(documentExpiryDate).getDate() < 10
        ? "0" + new Date(documentExpiryDate).getDate()
        : new Date(documentExpiryDate).getDate()
    }`,
    phonenumber,
    companyName,
    expiry: expiry,
  });
};

var controller = new AbortController();
var signal = controller.signal;
//R230331 changed to the one alredy available in c:\sunCardService\test.html, because
// - we don't have to maintain two version of the same functionality,
// - the original one didn't had timeout
// - the original wasn't able to produce error codes on client-side errors
export const mrzScanRequest = async () => {
  ///////////////////////////////////////////////////////////////////////////
  // MRZ READER START
  // connects to the node-js based MRZ reader via http.
  // Converts the result - or in case of error simulates a result to a "standard result" -> status=ok + data  / status=error + data= {errorCode, errorText, errorNumber}
  function isObject(v) { //R 2021.01.16
    // based on: https://stackoverflow.com/questions/8511281/check-if-a-value-is-an-object-in-javascript   in this comment:
    // After reading and trying out a lot of implementations, I've noticed that very few people try to check for values like JSON, Math, document or objects with prototype chains longer than 1 step.
    while (     Object.prototype.toString.call(v) === '[object Object]')
      if  ((v = Object.getPrototypeOf(v))         === null)  return true;
      return false
   } // isObject

  // merges a standard result to an other. Only the more servere is merged to the lower severe
  // severity order: unknown -> run -> abort -> timeout -> error -> ok
  function mergeResult(result1, result2) {
    var result1Severity = ["unknown", "run", "abort", "timeout", "error", "ok"].indexOf(result1.status);
    var result2Severity = ["unknown", "run", "abort", "timeout", "error", "ok"].indexOf(result2.status);
    if (result1Severity < result2Severity) { // must copy the inner status + data properties. Else JS will just use the reference
      result1.status= result2.status;
      if ( isObject(result1.data) && isObject(result2.data) ) // if all the two data are object then merges them for one level
        Object.assign(result1.data, result2.data)
      else result1.data= result2.data;
    }
   }

  try {
    // this will be the result of the whole function
    // it receives the same structure from the node-js server, so we just have to create this in the case of local error
    var result = {
      status: "unknown",    // ok/error/unknown
      data: {
        mrzText: "",        // raw mrzText
        parsed: null,       // parsed mrzText as object
        versionService: "", // timestamp of service/main.js in this format: "2020-10-20 23:30:23". In local timezone. Also the html is in this temozone
        errorMessage: "",   // error as human readable string
        errorCode: "",      // error as computer readable string. english, short, can be used in "if"
        errorNumber: 0,     // error as error number
       } // message
     } // result

    //!DEBUG console.log("getMrzReaderStatus");
    // async http request with timeout
    controller = new AbortController();
    signal = controller.signal;
    var res=await Promise.race([
      new Promise(
        function(resolve) {
          setTimeout(function() {
            resolve({status:"timeout", data:""});
            controller.abort();
           }
          , 22*1000); // setTimeout 22 seconds. The MRZ reader is set to 20 second timeout
         } //resolve
       ), //new Promise
      fetch("http://localhost:8080?action=mrzRead" + ( process.env.REACT_APP_MRZ_ENV === "dev" ? "&simulate=1" : "" ), {
        signal: signal,
        method: "POST",
        body: JSON.stringify( {
          jsonTestField: "TestData",
         }), // postdata.toJSON
        headers: {
          "Accept": "application/json, text/plain, */*",
          "Content-Type": "application/json"
         },  // headers
        }), // fetch
      ]); //promise.race
    // --- AWAIT ---
    if (res.json) res= await res.json();
    // ---- AWAIT ----
   } //try
  catch (error) {
    mergeResult(result, {
      status: "error",
      data: {
        errorMessage: error.message,
        errorNumber: 100,
        errorCode: "exception",
       } // data
     }) // mergeResult;
   } // catch

  if (result.status === "unknown") { // there wasn't any exception until this point
    if (res && (res.status === "timeout")) { // the service is not available
      mergeResult( result, { // if we hadn't error until this pont, now we'll have the TIMEOUT as result.status
        status: "error",
        data: {
          errorMessage: "EN:The helper service is not running or blocked. HU:Az seg�dprogram nem fut, vagy blokkolt�k",
          errorNumber: 101,
          errorCode: "noService",
         }, // data
       }); // mergeResult
     } // if timeout

    // simulate an error if we did't receive a standard result, or we didn't receive anyting (for example because of CORS)
    else if ( (! res) || (! res.status) || (! res.data) ) { //didn't receive standard result (status+data)
      mergeResult( result, { // if there wasn't any error until this point, now the result will be the illegal structure error
        status: "error",
        data: {
          errorMessage: "EN: The helper service returned an illegal result. HU: Hib�s v�laszt adott az seg�dprogramot",
          errorNumber: 102,
          errorCode: "noResult",
         }, // data
       }); // mergeResult
     } // not a standard result received
    // in all other cases we received a good structured standard result from the nodejs
    else result= res;
   } // if there wasn't any exception

  return result;
  // MRZ READER END


  //R this was the original one:
  //
  // var controller = new AbortController();
  // var signal = controller.signal;
  // return fetch(
  //   `http://localhost:8080?action=mrzRead${
  //     process.env.REACT_APP_MRZ_ENV === "dev" ? "&simulate=1" : ""
  //   }`,
  //   {
  //     signal,
  //     method: "POST",
  //     body: JSON.stringify({
  //       jsonTestField: "TestData",
  //     }),
  //     headers: {
  //       Accept: "application/json, text/plain, */*",
  //       "Content-Type": "application/json",
  //     },
  //   }
  // );
};

export const abortScanRequest = () => controller.abort();
