import React from "react";
import { useSelector } from "react-redux";
import Welcome from "./Welcome";
import VideoPage from "./VideoPage";
import FinalPage from "./FinalPage";
import LanguageSelect from "./LanguageSelect";
import SecondPage from "./SecondPage";

function StepController(props) {
  const { step } = useSelector((state) => state.form);
  return (
    <>
      {step === 0 ? (
        <LanguageSelect />
      ) : step === 1 ? (
        <Welcome />
      ) : step === 2 ? (
        <SecondPage />
      ) : step === 3 ? (
        <VideoPage />
      ) : step === 4 ? (
        <FinalPage />
      ) : null}
    </>
  );
}

export default StepController;
