import React, { useEffect, useState } from "react";
import styled from "styled-components";
import en from "../assets/languageFlags/en.png";
import de from "../assets/languageFlags/de.png";
import hu from "../assets/languageFlags/hu.png";
import KnorrBackground from "../assets/Knorr_3_flags.jpg";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage, changeProcessStep, logout } from "../redux/actions";
import { visitorManagement } from "../redux/Api";

const Container = styled.div``;

const HeaderDesc = styled.h6`
  font-size: 20px;
  font-weight: 400;
  color: #848484;
  margin: 24px 0;
  text-align: center;
`;

const FlagContainer = styled.div`
  width: 100%;
  min-height: 45vh;
  height: calc(97vh - 200px);
  background-image: url(${KnorrBackground});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

const FlagContainerShade = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  padding-bottom: 1px;
  background-color: rgba(0, 0, 0, 0.65);
  & > * {
    &:last-child {
      margin-right: 0;
    }
  }
`;

const FlagItem = styled.div`
  cursor: pointer;
  background: #004481;
  height: fit-content;
  width: 205px;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.5s;
  & > img {
    height: 24px;
    width: 24px;
  }
  &:hover {
    transition: 0.5s;
    opacity: 0.8;
  }
  & > h3 {
    font-size: 24px;
    margin-top: 8px;
    font-weight: 600;
    color: #fff;
  }
`;

function LanguageSelect() {
  const dispatch = useDispatch();
  const [isTested, setIsTested] = useState(false);

  useEffect(() => {
    if (!isTested) {
      setIsTested(true);
      visitorManagement().catch((err) => console.log(err));
    }
  }, [isTested]);
  const handleSelectLang = (lang) => {
    dispatch(changeLanguage(lang));
    dispatch(changeProcessStep(1));
  };
  return (
    <Container>
      <HeaderDesc>
        Please, select language! | Bitte wähle eine Sprache! | Kérjük, válasszon
        nyelvet!
      </HeaderDesc>
      <FlagContainer>
        <FlagContainerShade>
          <FlagItem onClick={() => handleSelectLang("en")}>
            <img src={en} />
            <h3>English</h3>
          </FlagItem>
          <FlagItem onClick={() => handleSelectLang("de")}>
            <img src={de} />
            <h3>Deutsch</h3>
          </FlagItem>
          <FlagItem onClick={() => handleSelectLang("hu")}>
            <img src={hu} />
            <h3>Magyar</h3>
          </FlagItem>
        </FlagContainerShade>
      </FlagContainer>
    </Container>
  );
}

export default LanguageSelect;
