import { combineReducers, legacy_createStore as createStore } from "redux";
import userReducer from "./userReducer";
import formReducer from "./formReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["form"],
};

const rootReducers = combineReducers({
  user: userReducer,
  form: formReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = createStore(persistedReducer);

let persistor = persistStore(store);

export { store, persistor };
