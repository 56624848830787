import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Registrant from "./Registrant";
import unCheckedCheckbox from "../assets/unCheckCheckbox.svg";
import checkedCheckbox from "../assets/checkedCheckbox.svg";
import Button from "./Button";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import { changeProcessStep } from "../redux/actions";
import PersonalData from "../assets/PersonalData.svg";
import AddAnotherIcon from "../assets/addAnother.svg";

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const RegisteredVisitor = styled.h3`
  margin-top: 74px;
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
`;
const VisitorsContainer = styled.div`
  padding: 20px;
  height: 100%;
`;

const NoRegisteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const NoRegisteredText = styled.span`
  font-size: 28px;
  font-weight: 400;
  color: #848484;
`;

const CheckboxText = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #848484;
  margin-left: 8px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1350px) {
    flex-direction: column;
    gap: 16px;
    & > * {
      width: 100%;
    }
  }
`;

const ErrorText = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 8px;
`;

const RegistrantsContainer = styled.div`
  max-height: 55vh;
  overflow: auto;
`;

const WelcomeBannerImg = styled.img`
  height: 100%;
  width: 100%;
`;

function MultipleRegistrationBanner({ handleAddRegistrant }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { registeredPersons } = useSelector((state) => state.form);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState({
    status: false,
    errorMessage: "",
  });

  useEffect(() => {
    setError({
      status: false,
      errorMessage: "",
    });
  }, [registeredPersons]);

  const handleProcessStep = () => {
    if (!checked) {
      setError({
        status: true,
        errorMessage: intl.formatMessage({ id: "PLEASE_CHECK_CHECKBOX" }),
      });
      return;
    }
    if (registeredPersons.length < 2) {
      setError({
        status: true,
        errorMessage: intl.formatMessage({ id: "REGISTRANT_BELOW_2_ERROR" }),
      });
      return;
    }
    dispatch(changeProcessStep(3));
  };

  return (
    <Container>
      <RegisteredVisitor>
        {intl.formatMessage({ id: "REGISTERED_VISITORS" })}
      </RegisteredVisitor>
      <VisitorsContainer>
        {registeredPersons.length > 0 ? (
          <>
            <RegistrantsContainer>
              {registeredPersons.map((registrant, index) => (
                <Registrant key={index} registrant={registrant} />
              ))}
            </RegistrantsContainer>
            {error.status && <ErrorText>{error.errorMessage}</ErrorText>}
            <CheckboxContainer>
              <img
                onClick={() => setChecked(!checked)}
                style={{ cursor: "pointer" }}
                src={checked ? checkedCheckbox : unCheckedCheckbox}
                alt={checked ? "checked" : "unchecked"}
              />
              <CheckboxText>
                {intl.formatMessage({ id: "MULTIPLE_VIDEO_CHECKBOX" })}
              </CheckboxText>
            </CheckboxContainer>

            <ButtonContainer>
              <Button
                onClick={handleAddRegistrant}
                disabled={registeredPersons.length >= 5}
                style={{
                  background: "#fff",
                  color: "#004481",
                  marginRight: 16,
                  padding: "0 .5em",
                }}
              >
                <ArrowImg src={AddAnotherIcon} alt="right-arrow" />
                {intl.formatMessage({
                  id: "ADD_ANOTHER",
                })}
              </Button>
              <Button
                onClick={handleProcessStep}
                disabled={registeredPersons.length < 2 || !checked}
              >
                {intl.formatMessage({
                  id: "WATCH_VIDEO",
                })}
                <ArrowImg src={RightArrowSm} alt="right-arrow" />
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <NoRegisteredContainer>
            <WelcomeBannerImg src={PersonalData} alt="welcome-banner" />
            <NoRegisteredText>
              {intl.formatMessage({ id: "NO_REGISTERED_USERS" })}
            </NoRegisteredText>
          </NoRegisteredContainer>
        )}
      </VisitorsContainer>
    </Container>
  );
}

export default MultipleRegistrationBanner;
