import React, { useState } from "react";
import timesIcon from "../assets/timesIcon.svg";
import Button from "./Button";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import RightArrowBlueSm from "../assets/right-arrow-sm-blue.svg";
import { useIntl } from "react-intl";
import styled from "styled-components";

const Background = styled.div`
  background: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalInner = styled.div`
  border-radius: 18px;
  background: #fff;
  position: relative;
  max-width: 40vw;
  padding: 50px;
  max-height: 80vh;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #464e5f;
`;

const ModalText = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #80808f;
  margin: 20px 0;
  white-space: break-spaces;
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const TimesIcon = styled.img`
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
`;

const Radio = styled.input`
  width: 2em;
  height: 2em;
  accent-color: #004481;
  margin-right: 1em;
`;

const GrayText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: #848484;
`;

function MultipleRegistrationModal({ closeModal, onProceed }) {
  const [isSelected, setIsSelected] = useState(0);
  const intl = useIntl();
  return (
    <Background>
      <ModalInner>
        <Header>
          <HeaderText>
            {intl.formatMessage({ id: "SELECT_NUMBER_OF_REGISTRANTS" })}
          </HeaderText>
          <TimesIcon src={timesIcon} alt="times-icon" onClick={closeModal} />
        </Header>
        <ModalText>
          {intl.formatMessage({ id: "SELECT_NUMBER_OF_REGISTRANTS_DESC" })}
          <RadioGroup>
            <Radio
              type="radio"
              checked={isSelected === 2}
              onChange={(e) => setIsSelected(2)}
            />
            <GrayText onClick={() => setIsSelected(2)}>2</GrayText>
          </RadioGroup>
          <RadioGroup>
            <Radio
              type="radio"
              checked={isSelected === 3}
              onChange={(e) => setIsSelected(3)}
            />
            <GrayText onClick={() => setIsSelected(3)}>3</GrayText>
          </RadioGroup>
          <RadioGroup>
            <Radio
              type="radio"
              checked={isSelected === 4}
              onChange={(e) => setIsSelected(4)}
            />
            <GrayText onClick={() => setIsSelected(4)}>4</GrayText>
          </RadioGroup>
          <RadioGroup>
            <Radio
              type="radio"
              checked={isSelected === 5}
              onChange={(e) => setIsSelected(5)}
            />
            <GrayText onClick={() => setIsSelected(5)}>5</GrayText>
          </RadioGroup>
        </ModalText>
        <ButtonContainer>
          <Button onClick={closeModal} isSolid>
            {intl.formatMessage({ id: "CANCEL" })}
            <ArrowImg src={RightArrowBlueSm} alt="right-arrow" />
          </Button>
          <Button
            onClick={() => {
              if (isSelected) {
                onProceed();
              }
            }}
            disabled={!isSelected}
          >
            {intl.formatMessage({ id: "PROCEED" })}
            <ArrowImg src={RightArrowSm} alt="right-arrow" />
          </Button>
        </ButtonContainer>
      </ModalInner>
    </Background>
  );
}

export default MultipleRegistrationModal;
