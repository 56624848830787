import React from "react";
import LogoBlue from "../assets/logo-blue.svg";
import styled from "styled-components";
import LanguageDropdown from "./LanguageDropdown";
import { useSelector } from "react-redux";

const Container = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 60px;
  justify-content: space-between;
  justify-content: ${(props) => (props.step === 0 ? "center" : "space-between")};
`;

function Header() {
  const { step } = useSelector((state) => state);
  return (
    <Container step={step}>
      <img src={LogoBlue} alt={"logo-blue"} />
      {step === 0 || step === 5 ? null : <LanguageDropdown isLabelBlack />}
    </Container>
  );
}

export default Header;
