import React from "react";
import styled from "styled-components";
import timesIcon from "../assets/timesIcon.svg";
import Button from "./Button";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import { useIntl } from "react-intl";
import RightArrowBlueSm from "../assets/right-arrow-sm-blue.svg";

const Background = styled.div`
  background: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalInner = styled.div`
  border-radius: 18px;
  background: #fff;
  position: relative;
  max-width: 40vw;
  padding: 50px;
  max-height: 80vh;
  overflow: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #464e5f;
`;

const ModalText = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #80808f;
  margin: 20px 0;
  white-space: break-spaces;
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const TimesIcon = styled.img`
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function PreviousPageModal({ closeModal, onProceed }) {
  const intl = useIntl();
  return (
    <Background>
      <ModalInner>
        <Header>
          <HeaderText>
            {intl.formatMessage({ id: "ARE_YOU_SURE" })}
          </HeaderText>
          <TimesIcon src={timesIcon} alt="times-icon" onClick={closeModal} />
        </Header>
        <ModalText>
          {intl.formatMessage({ id: "RESET_PROCESS_MODAL_DESC" })}
        </ModalText>
        <ButtonContainer>
          <Button onClick={closeModal} isSolid>
            {intl.formatMessage({ id: "CANCEL" })}
            <ArrowImg src={RightArrowBlueSm} alt="right-arrow" />
          </Button>
          <Button onClick={onProceed}>
            {intl.formatMessage({ id: "PROCEED" })}
            <ArrowImg src={RightArrowSm} alt="right-arrow" />
          </Button>
        </ButtonContainer>
      </ModalInner>
    </Background>
  );
}

export default PreviousPageModal;
