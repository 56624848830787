import React, { useState } from "react";
import styled from "styled-components";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useIntl } from "react-intl";

const LabelContainer = styled.div`
  width: calc(50% - 16px);
  margin-bottom: 30px;
`;

const InputContainer = styled.div`
  background: #f3f6f9;
  border-radius: 9px;
  height: 69px;
  padding: 0 23px;
  margin-top: 8px;
  display: flex;
  align-items: center;
`;

const InputElement = styled.input`
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 18px;
`;

const SelectElement = styled.select`
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 18px;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  color: #2f3044;
`;

const Required = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: #ff0000;
  margin-left: 4px;
`;

function Input({
  label,
  isRequired,
  isDatePicker,
  value,
  setValue,
  isSelect,
  error,
  errorText,
}) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <LabelContainer>
      <Label>{label}</Label>
      {isRequired && <Required>*</Required>}
      {error && <Required>{errorText}</Required>}
      <InputContainer>
        {isDatePicker ? (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date desktop"
              inputFormat="MM/DD/YYYY"
              value={!open ? value : ""}
              onChange={handleChange}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant={"standard"}
                  sx={{
                    "& > label": { display: "none" },
                    "& > div": { marginTop: "0" },
                    "& > div > input": { padding: "0 0 0 0", fontSize: 18 },
                    "& > div:before": { border: "none !important" },
                    "& > div:after": { border: "none !important" },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        ) : isSelect ? (
          <SelectElement
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <option value="" disabled>
              {intl.formatMessage({
                id: "DATA_PROTECTION_NEXT_ERROR",
              })}
            </option>
            <option value="idcard">
              {intl.formatMessage({ id: "ID_CARD" })}
            </option>
            <option value="passport">
              {intl.formatMessage({ id: "PASSPORT" })}
            </option>
            <option value="drivinglicense">
              {intl.formatMessage({ id: "DRIVING_LICENSE" })}
            </option>
          </SelectElement>
        ) : (
          <InputElement
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        )}
      </InputContainer>
    </LabelContainer>
  );
}

export default Input;
