import Router from "./Router";
import "./styles/app.css";
import { Provider } from "react-redux";
import setupAxios from "./redux/setupAxios";
import axios from "axios";
import { I18nProvider } from "./localization/IntlProvider";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

setupAxios(axios, store);
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nProvider>
          <Router />
        </I18nProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
