import React, { useState } from "react";
import styled from "styled-components";
import protectIcon from "../assets/protectIcon.svg";
import deleteIcon from "../assets/registrantDelete.svg";
import editIcon from "../assets/registrantEdit.svg";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { editRegistrant, removeRegistrant } from "../redux/actions";
import DeleteRegistrantConfirmModal from "./DeleteRegistrantConfirmModal";
import chevronIcon from "../assets/chevronIcon.svg";
import dayjs from "dayjs";

const Container = styled.div`
  background: #fff;
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 20px;
`;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${(props) =>
    props.isExpanded ? "1px solid #84848433" : "none"};
  padding-bottom: ${(props) => (props.isExpanded ? "16px" : "0")};
`;

const Name = styled.h4`
  font-weight: 600;
  font-size: 20px;
  color: #2f3044;
`;

const LeftContainer = styled.div`
  margin-left: 16px;
`;

const NameContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const Dot = styled.span`
  margin: 0 8px;
  color: #848484;
  font-weight: 400;
  font-size: 16px;
`;
const CompanyName = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #848484;
`;

const ExpiryContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ExpiryText = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #848484;
  margin-left: 8px;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 16px;
    cursor: pointer;
    transition: 0.5s;

    &:hover {
      opacity: 0.8;
      transition: 0.5s;
    }
  }
`;

const IconContainer = styled.div``;

const IconBackground = styled.div`
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px;
  background: #f3f6f9;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 6px;
`;

const ExpandContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
`;

const Expand = styled.div``;

const ExpandText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #2f3044;
`;

const ExpandTitle = styled.h6`
  font-size: 14px;
  font-weight: 500;
  color: #848484;
`;

function Registrant({ registrant }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleEdit = () => {
    dispatch(editRegistrant(registrant.docNo));
  };

  return (
    <Container>
      <FirstRow isExpanded={isExpanded}>
        {deleteModal && (
          <DeleteRegistrantConfirmModal
            closeModal={() => setDeleteModal(false)}
            deleteRegistrant={() =>
              dispatch(removeRegistrant(registrant.docNo))
            }
          />
        )}
        <IconContainer>
          <IconBackground onClick={() => setIsExpanded(!isExpanded)}>
            <img
              src={chevronIcon}
              alt="chevron"
              style={{
                userSelect: "none",
                transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </IconBackground>
        </IconContainer>
        <LeftContainer>
          <NameContainer>
            <Name>{`${registrant.firstName} ${registrant.lastName}`}</Name>
            {registrant.companyName && (
              <>
                <Dot>.</Dot>
                <CompanyName>{registrant.companyName}</CompanyName>
              </>
            )}
          </NameContainer>
          <ExpiryContainer>
            <img src={protectIcon} alt="protect" />
            <ExpiryText>
              {registrant.expiry === 1
                ? intl.formatMessage({ id: "72_HOURS_REGISTRANT_DESC" })
                : registrant.expiry === 2
                ? intl.formatMessage({ id: "1_YEAR_REGISTRANT_DESC" })
                : ""}
            </ExpiryText>
          </ExpiryContainer>
        </LeftContainer>
        <RightContainer>
          <img src={deleteIcon} onClick={handleDelete} alt="delete" />
          <img src={editIcon} onClick={handleEdit} alt="edit" />
        </RightContainer>
      </FirstRow>
      {isExpanded && (
        <ExpandContainer>
          <Expand>
            <ExpandTitle>
              {intl.formatMessage({ id: "DATE_OF_BIRTH" })}
            </ExpandTitle>
            <ExpandText>
              {dayjs(registrant?.dateOfBirth)?.format("DD/MMM/YYYY") || ""}
            </ExpandText>
          </Expand>
          <Expand>
            <ExpandTitle>{intl.formatMessage({ id: "DOC_NO" })}</ExpandTitle>
            <ExpandText>{registrant.docNo}</ExpandText>
          </Expand>
          <Expand>
            <ExpandTitle>
              {intl.formatMessage({ id: "DOC_EXPIRY_DATE" })}
            </ExpandTitle>
            <ExpandText>
              {dayjs(registrant?.docExpiryDate)?.format("DD/MMM/YYYY") || ""}
            </ExpandText>
          </Expand>
        </ExpandContainer>
      )}
    </Container>
  );
}

export default Registrant;
