import { REFRESH_TOKEN_URL } from "./Api";
import { changeAccessToken, logout } from "./actions";

function setupAxios(axios, store) {
  //On Request
  const requestInterceptor = axios.interceptors.request.use((config) => {
    const { token, selectedLang } = store.getState()?.user;
    config.headers["x-language"] = `${selectedLang || "en"}`;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  let isAlreadyFetchingAccessToken = false;
  let subscribers = [];

  function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter((callback) => callback(access_token));
  }

  function addSubscriber(callback) {
    subscribers.push(callback);
  }

  //On Response
  const responseInterceptor = axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      if (
        err?.response?.status === 401 &&
        err?.response?.data?.errorCode === 4
      ) {
        try {
          const { refreshToken, selectedLang } = store.getState()?.user;

          const { config } = err;
          const originalRequest = config;

          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            fetch(REFRESH_TOKEN_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "x-language": selectedLang || "en",
                Authorization: "Bearer " + refreshToken,
              },
            })
              .then((response) => {
                return response.json();
              })
              .then((data) => {
                const newToken = data.accessToken;
                onAccessTokenFetched(newToken);
                isAlreadyFetchingAccessToken = false;
              })
              .catch((err) => {
                console.log("error when refreshing token");
                store.dispatch(logout());
              });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
              originalRequest.headers.Authorization = "Bearer " + access_token;
              axios.defaults.headers.Authorization = "Bearer " + access_token;
              store.dispatch(changeAccessToken(access_token));
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        } catch (e) {
          console.log("error when refresh token progress");
          logout();
        } finally {
          axios.interceptors.request.use((config) => {
            const { token, selectedLang } = store.getState()?.user;
            console.log("here works with new token", token);
            config.headers["x-language"] = `${selectedLang || "en"}`;
            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
          });
        }
      } else {
        return Promise.reject(err);
      }
    }
  );
}

export default setupAxios;
