import React, { useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import { changeProcessStep, changeRegistrationType } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import MultipleRegistrationModal from "../components/MultipleRegistrationModal";

const Container = styled.div`
  width: 60vw;
`;

const HeaderText = styled.h1`
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
  border-bottom: 1px solid #dde4ea;
  padding-bottom: 16px;
`;

const DescText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #848484;
  margin: 24px 0;
  white-space: break-spaces;
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3em;
`;

function Welcome(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const handleNewCheckIn = () => {
    setModal(true);
  };
  const handleModalProceed = (type) => {
    if (type === "multiple") {
      setModal(true);
    } else {
      dispatch(changeRegistrationType(type));
      dispatch(changeProcessStep(2));
    }
  };

  return (
    <Container>
      {modal && (
        <MultipleRegistrationModal
          onProceed={() => {
            dispatch(changeRegistrationType("multiple"));
            dispatch(changeProcessStep(2));
          }}
          closeModal={() => setModal(false)}
        />
      )}
      <HeaderText>{intl.formatMessage({ id: "WELCOME_HEADER" })}</HeaderText>
      <DescText>{intl.formatMessage({ id: "WELCOME_DESC" })}</DescText>
      <ButtonContainer>
        {/*
          <Button isSolid style={{ marginRight: "1em" }}>
          Already registered
          <ArrowImg src={RightArrowSmBlue} alt="right-arrow-blue" />
        </Button>
           */}
        <Button
          onClick={() => handleModalProceed("single")}
          style={{ marginBottom: "2em" }}
        >
          {intl.formatMessage({ id: "SINGLE_PERSON" })}
          <ArrowImg src={RightArrowSm} alt="right-arrow" />
        </Button>
        <Button
          onClick={() => handleModalProceed("multiple")}
          style={{ marginBottom: "2em", marginLeft: "1em" }}
        >
          {intl.formatMessage({ id: "MULTIPLE_PERSONS" })}
          <ArrowImg src={RightArrowSm} alt="right-arrow" />
        </Button>
      </ButtonContainer>
    </Container>
  );
}

export default Welcome;
