import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./pages/Login";
import { useSelector } from "react-redux";
import StepController from "./pages/StepController";

function Router() {
  const { username, password } = useSelector((state) => state.user);
  const isLogin = username && password;
  return (
    <BrowserRouter basename={"/"}>
      {isLogin ? (
        <Routes>
          <Route element={<Layout />}>
            <Route path={"/"} element={<StepController />} />
          </Route>
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      ) : (
        <Routes>
          <Route path={"/"} element={<Login />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

export default Router;
