import React, { useEffect } from "react";
import styled from "styled-components";
import KnorrFlag from "../assets/knorr-flag.jpg";
import WelcomeBanner from "../assets/welcome-banner.png";
import DataProtection from "../assets/dataProtection.svg";
import Video from "../assets/Video.svg";
import PersonalData from "../assets/PersonalData.svg";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import MultipleRegistrationBanner from "./MultipleRegistrationBanner";
import MultipleRegistrationVideoBanner from "./MultipleRegistrationVideoBanner";
import {
  addRegistrant,
  changeProcessStep,
  editRegistrant,
  setCompanyName,
  setDateOfBirth,
  setDocExpiryDate,
  setDocNo,
  setDocumentType,
  setError,
  setExpiry,
  setFirstName,
  setLastName,
  setLoading,
  setPhoneNumber,
  updateRegistrant,
} from "../redux/actions";
import { toast, ToastContainer } from "react-toastify";
import { useIntl } from "react-intl";
import "react-toastify/dist/ReactToastify.css";

const LayoutContainer = styled.div`
  height: 100vh;
  width: 100vw;
`;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 36px);
`;

const ImageBanner = styled.div`
  width: 35vw;
  height: 100%;
  background: #f3f6f9;

  & > div {
    overflow: auto;
  }
`;

const LeftContainer = styled.div`
  padding: 0 80px;
  overflow-x: hidden;
  overflow-y: auto;
  width: ${(props) =>
    props.step === 0 || props.step === 5 ? "100vw" : "65vw"};
  height: 97vh;
`;

const WelcomeBannerImg = styled.img`
  height: 100%;
  width: 100%;
`;

const Footer = styled.div`
  background: #f3f3f3;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  position: relative;
`;

const Link = styled.span`
  color: blue;
  margin: 0 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

function Layout() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    step,
    registrationType,
    loading,
    expiry,
    docExpiryDate,
    firstName,
    lastName,
    docNo,
    documentType,
    registeredPersons,
    editRegistrantId,
    dateOfBirth,
    phoneNumber,
    companyName,
  } = useSelector((state) => state.form);

  useEffect(() => {
    if (
      !expiry ||
      !firstName ||
      !lastName ||
      !docNo ||
      !documentType ||
      !docExpiryDate?.isValid() ||
      !dateOfBirth?.isValid()
    ) {
      dispatch(setError({ status: true, errorMessage: "" }));
      return;
    }
    if (!expiry || expiry === "0") {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({
            id: "DATA_PROTECTION_NEXT_ERROR",
          }),
        })
      );
      dispatch(setLoading(false));
      return;
    }

    if (
      !docExpiryDate?.toDate() ||
      !docExpiryDate?.isValid() ||
      docExpiryDate.toDate().getTime() <= new Date().getTime()
    ) {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({ id: "DOC_DATE_EXPIRED" }),
        })
      );
      dispatch(setLoading(false));
      return;
    }
    if (
      firstName.length &&
      lastName.length &&
      docNo.length &&
      documentType.length
    ) {
    } else {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({ id: "PERSONAL_DATA_NEXT_ERROR" }),
        })
      );
      return;
    }
    dispatch(setError({ status: false, errorMessage: "" }));
  }, [expiry, docExpiryDate, firstName, lastName, docNo, documentType]);

  const clearState = () => {
    dispatch(setFirstName(""));
    dispatch(setLastName(""));
    dispatch(setDateOfBirth(null));
    dispatch(setDocumentType(""));
    dispatch(setDocNo(""));
    dispatch(setDocExpiryDate(null));
    dispatch(setCompanyName(""));
    dispatch(setPhoneNumber(""));
    dispatch(setExpiry(""));
    dispatch(setError({ status: false, errorMessage: "" }));
    let el = document.getElementById("leftContainer");
    el?.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleAddRegistrant = () => {
    if (loading) return;
    dispatch(setLoading(true));

    if (
      !expiry ||
      expiry === "0" ||
      !docExpiryDate?.toDate() ||
      docExpiryDate.toDate().getTime() <= new Date().getTime()
    ) {
      toast.info(
        `${intl.formatMessage({ id: "ADD_ANOTHER_BUTTON_TOASTER" })}`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      dispatch(setLoading(false));
      return;
    }

    if (!expiry || expiry === "0") {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({
            id: "DATA_PROTECTION_NEXT_ERROR",
          }),
        })
      );
      dispatch(setLoading(false));
      return;
    }

    if (
      !docExpiryDate?.toDate() ||
      !docExpiryDate?.isValid() ||
      docExpiryDate.toDate().getTime() <= new Date().getTime()
    ) {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({ id: "DOC_DATE_EXPIRED" }),
        })
      );
      dispatch(setLoading(false));
      return;
    }
    if (
      firstName.length &&
      lastName.length &&
      docNo.length &&
      documentType.length
    ) {
      dispatch(setLoading(false));
      if (
        registrationType === "multiple" &&
        registeredPersons.find((person) => person.docNo === docNo)
      ) {
        // If updating a registrant, don't show error
        if (editRegistrantId === docNo) {
          dispatch(
            updateRegistrant({
              firstName,
              lastName,
              dateOfBirth,
              documentType,
              docNo,
              docExpiryDate,
              phoneNumber,
              companyName,
              expiry: expiry === "72hours" ? 1 : expiry === "1year" ? 2 : 0,
            })
          );
          dispatch(editRegistrant(null));
          clearState();
          return;
        }
        dispatch(
          setError({
            status: true,
            errorMessage: intl.formatMessage({
              id: "PERSON_ALREADY_REGISTERED_ERROR",
            }),
          })
        );
        return;
      }
      if (registrationType === "multiple") {
        if (registeredPersons.length >= 5) {
          dispatch(
            setError({
              status: true,
              errorMessage: intl.formatMessage({ id: "MAX_REGISTRANTS_ERROR" }),
            })
          );
          return;
        }
        // multiple registration
        dispatch(
          addRegistrant({
            firstName,
            lastName,
            dateOfBirth,
            documentType,
            docNo,
            docExpiryDate,
            phoneNumber,
            companyName,
            expiry: expiry === "72hours" ? 1 : expiry === "1year" ? 2 : 0,
          })
        );
        toast.success(
          `${intl.formatMessage(
            { id: "PERSON_ADDED" },
            { name: `${firstName} ${lastName}` }
          )}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        clearState();
        return;
      } else {
        dispatch(
          addRegistrant({
            firstName,
            lastName,
            dateOfBirth,
            documentType,
            docNo,
            docExpiryDate,
            phoneNumber,
            companyName,
            expiry: expiry === "72hours" ? 1 : expiry === "1year" ? 2 : 0,
          })
        );
        dispatch(changeProcessStep(3));
      }
    } else {
      dispatch(setLoading(false));
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({ id: "PERSONAL_DATA_NEXT_ERROR" }),
        })
      );
    }
  };

  return (
    <LayoutContainer>
      <Container>
        <LeftContainer step={step} id={"leftContainer"}>
          <ToastContainer />
          <Header />
          <Outlet />
        </LeftContainer>
        {step !== 0 && step !== 5 && (
          <ImageBanner>
            {step === 3 && registrationType === "multiple" ? (
              <MultipleRegistrationVideoBanner />
            ) : step === 2 && registrationType === "multiple" ? (
              <MultipleRegistrationBanner
                handleAddRegistrant={handleAddRegistrant}
              />
            ) : (
              <WelcomeBannerImg
                src={
                  step === 0
                    ? KnorrFlag
                    : step === 1
                    ? WelcomeBanner
                    : step === 2
                    ? DataProtection
                    : step === 3
                    ? Video
                    : step === 4
                    ? PersonalData
                    : ""
                }
                alt="welcome-banner"
              />
            )}
          </ImageBanner>
        )}
      </Container>
      <Footer>
        Developed by <Link>Sectran Ltd</Link> - All rights reserved 2023 ©
      </Footer>
    </LayoutContainer>
  );
}

export default Layout;
