import React, { useState } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Button from "./Button";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import { changeProcessStep, resetProcess } from "../redux/actions";
import RegistrantVideo from "./RegistrantVideo";
import RightArrowSmBlue from "../assets/right-arrow-sm-blue.svg";
import VideoConfirmWarning from "./VideoConfirmWarning";
import { visitorManagement } from "../redux/Api";

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
`;

const RegisteredVisitor = styled.h3`
  margin-top: 74px;
  margin-left: 20px;
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
`;
const DescText = styled.h3`
  margin-left: 20px;
  font-weight: 400;
  font-size: 16px;
  color: #80808f;
  padding-right: 12px;
`;
const VisitorsContainer = styled.div`
  padding: 40px;
  height: 100%;
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VideoWarn = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: #80808f;
  text-align: end;
  width: 100%;
`;

const ArrowLeftImg = styled.img`
  margin: auto 0.5em auto;
  transform: rotate(180deg);
`;

const RegistrantsContainer = styled.div`
  max-height: 55vh;
  overflow: auto;
`;

const ErrorText = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 8px;
`;

function MultipleRegistrationBanner(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { registeredPersons, isVideoWatched } = useSelector(
    (state) => state.form
  );
  const [loading, setLoading] = useState(false);
  const [acceptWarningModal, setAcceptWarningModal] = useState({
    status: false,
    registrants: [],
  });
  const [error, setError] = useState({
    status: false,
    errorMessage: "",
  });

  const handleApi = () => {
    if (loading) return;
    setAcceptWarningModal(false);
    setLoading(true);
    const promises = [];
    registeredPersons
      .filter((item) => item.videoStatus === "accept")
      .map((registrant) => {
        promises.push(
          visitorManagement(
            registrant.documentType,
            `${registrant.firstName} ${registrant.lastName}`,
            registrant.docNo,
            registrant.dateOfBirth,
            registrant.docExpiryDate,
            registrant.phoneNumber,
            registrant.companyName,
            registrant.expiry === 1 ? "3d" : registrant.expiry === 2 ? "1y" : ""
          )
        );
      });
    Promise.all(promises)
      .then(() => {
        setLoading(false);
        dispatch(changeProcessStep(4));
        dispatch(resetProcess());
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.data?.errorMessage) {
          setError({
            status: true,
            errorMessage: err?.response?.data?.errorMessage,
          });
        }
      });
  };

  const handleProcessStep = () => {
    if (loading) return;
    let checkRejectedUsers = registeredPersons.filter(
      (item) => item.videoStatus !== "accept" || !item.videoStatus
    );
    if (checkRejectedUsers.length) {
      setAcceptWarningModal({
        status: true,
        registrants: checkRejectedUsers,
      });
    } else {
      handleApi();
    }
  };

  const handlePrevious = () => {
    dispatch(changeProcessStep(2));
  };

  return (
    <Container>
      {acceptWarningModal.status && (
        <VideoConfirmWarning
          closeModal={() => setAcceptWarningModal(false)}
          registrants={acceptWarningModal.registrants}
          onConfirm={handleApi}
        />
      )}
      <RegisteredVisitor>
        {intl.formatMessage({ id: "ACCEPT_VIDEO" })}
      </RegisteredVisitor>
      <DescText>{intl.formatMessage({ id: "ACCEPT_VIDEO_DESC" })}</DescText>
      <VisitorsContainer>
        <>
          <RegistrantsContainer>
            {registeredPersons.map((registrant, index) => (
              <RegistrantVideo key={index} registrant={registrant} />
            ))}
          </RegistrantsContainer>

          {!isVideoWatched && (
            <VideoWarn>
              {intl.formatMessage({ id: "PLEASE_WAIT_UNTIL_END" })}
            </VideoWarn>
          )}
          {error.status && <ErrorText>{error.errorMessage}</ErrorText>}
          <ButtonContainer>
            <Button
              isSolid
              style={{ marginRight: "1em" }}
              onClick={handlePrevious}
            >
              <ArrowLeftImg src={RightArrowSmBlue} alt="right-arrow-blue" />
              {intl.formatMessage({ id: "PREVIOUS" })}
            </Button>
            <Button
              onClick={handleProcessStep}
              disabled={!isVideoWatched || loading}
            >
              {intl.formatMessage({
                id: "FINISH",
              })}
              <ArrowImg src={RightArrowSm} alt="right-arrow" />
            </Button>
          </ButtonContainer>
        </>
      </VisitorsContainer>
    </Container>
  );
}

export default MultipleRegistrationBanner;
