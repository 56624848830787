import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/logo.svg';
import LoginForm from "../components/Login/LoginForm";
import LanguageDropdown from "../components/LanguageDropdown";

const LoginBackground = styled.div`
  background: #004481;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  padding: 60px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    padding: 20px;
    flex-direction: column;
    & > img {
      margin-bottom: 1em;
      height: auto;
    }
  }
`;

function Login(props) {
    return (
        <LoginBackground>
            <Header>
                <img src={Logo} alt="logo"/>
                <LanguageDropdown />
            </Header>
            <LoginForm />
        </LoginBackground>
    );
}

export default Login;
