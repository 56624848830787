import React, { useEffect, useMemo, useState } from "react";
import DataProtection from "./DataProtection";
import FormPage from "./FormPage";
import Button from "../components/Button";
import RightArrowSmBlue from "../assets/right-arrow-sm-blue.svg";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import styled from "styled-components";
import {
  addRegistrant,
  changeProcessStep,
  editRegistrant,
  resetProcess,
  setCompanyName,
  setDateOfBirth,
  setDocExpiryDate,
  setDocNo,
  setDocumentType,
  setError,
  setExpiry,
  setFirstName,
  setLastName,
  setLoading,
  setPhoneNumber,
  updateRegistrant,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreviousPageModal from "../components/PreviousPageModal";

const ArrowLeftImg = styled.img`
  margin: auto 0.5em auto;
  transform: rotate(180deg);
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3em 0 5em 0;
  justify-content: space-between;
`;
function SecondPage(props) {
  const {
    registrationType,
    registeredPersons,
    editRegistrantId,
    firstName,
    lastName,
    dateOfBirth,
    documentType,
    docNo,
    docExpiryDate,
    companyName,
    phoneNumber,
    loading,
    expiry,
  } = useSelector((state) => state.form);
  const [previousPageModal, setPreviousPageModal] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (registrationType !== "multiple" && registeredPersons.length) {
      const registrant = registeredPersons[0];
      dispatch(setFirstName(registrant.firstName));
      dispatch(setLastName(registrant.lastName));
      dispatch(setDateOfBirth(registrant.dateOfBirth));
      dispatch(setDocumentType(registrant.documentType));
      dispatch(setDocNo(registrant.docNo));
      dispatch(setDocExpiryDate(registrant.docExpiryDate));
      dispatch(setCompanyName(registrant.companyName));
      dispatch(setPhoneNumber(registrant.phoneNumber));
      dispatch(
        setExpiry(
          registrant.expiry === 1
            ? "72hours"
            : registrant.expiry === 2
            ? "1year"
            : ""
        )
      );
    }
  }, []);

  useMemo(() => {
    if (editRegistrantId) {
      const registrant = registeredPersons.find(
        (person) => person.docNo === editRegistrantId
      );
      dispatch(setFirstName(registrant.firstName));
      dispatch(setLastName(registrant.lastName));
      dispatch(setDateOfBirth(registrant.dateOfBirth));
      dispatch(setDocumentType(registrant.documentType));
      dispatch(setDocNo(registrant.docNo));
      dispatch(setDocExpiryDate(registrant.docExpiryDate));
      dispatch(setCompanyName(registrant.companyName));
      dispatch(setPhoneNumber(registrant.phoneNumber));
      dispatch(
        setExpiry(
          registrant.expiry === 1
            ? "72hours"
            : registrant.expiry === 2
            ? "1year"
            : ""
        )
      );
    }
  }, [editRegistrantId]);

  const clearState = () => {
    dispatch(setFirstName(""));
    dispatch(setLastName(""));
    dispatch(setDateOfBirth(null));
    dispatch(setDocumentType(""));
    dispatch(setDocNo(""));
    dispatch(setDocExpiryDate(null));
    dispatch(setCompanyName(""));
    dispatch(setPhoneNumber(""));
    dispatch(setExpiry(""));
    dispatch(setError({ status: false, errorMessage: "" }));
    let el = document.getElementById("leftContainer");
    el?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClickNext = () => {
    if (loading) return;
    dispatch(setLoading(true));

    if (!expiry || expiry === "0") {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({
            id: "DATA_PROTECTION_NEXT_ERROR",
          }),
        })
      );
      dispatch(setLoading(false));
      return;
    }

    if (
      !docExpiryDate?.toDate() ||
      !docExpiryDate?.isValid() ||
      docExpiryDate.toDate().getTime() <= new Date().getTime()
    ) {
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({ id: "DOC_DATE_EXPIRED" }),
        })
      );
      dispatch(setLoading(false));
      return;
    }

    if (
      firstName.length &&
      lastName.length &&
      docNo.length &&
      documentType.length
    ) {
      dispatch(setLoading(false));
      if (
        registrationType === "multiple" &&
        registeredPersons.find((person) => person.docNo === docNo)
      ) {
        // If updating a registrant, don't show error
        if (editRegistrantId === docNo) {
          dispatch(
            updateRegistrant({
              firstName,
              lastName,
              dateOfBirth,
              documentType,
              docNo,
              docExpiryDate,
              phoneNumber,
              companyName,
              expiry: expiry === "72hours" ? 1 : expiry === "1year" ? 2 : 0,
            })
          );
          dispatch(editRegistrant(null));
          clearState();
          return;
        }
        dispatch(
          setError({
            status: true,
            errorMessage: intl.formatMessage({
              id: "PERSON_ALREADY_REGISTERED_ERROR",
            }),
          })
        );
        return;
      }
      if (registrationType === "multiple") {
        if (registeredPersons.length >= 5) {
          dispatch(
            setError({
              status: true,
              errorMessage: intl.formatMessage({ id: "MAX_REGISTRANTS_ERROR" }),
            })
          );
          return;
        }
        // multiple registration
        dispatch(
          addRegistrant({
            firstName,
            lastName,
            dateOfBirth,
            documentType,
            docNo,
            docExpiryDate,
            phoneNumber,
            companyName,
            expiry: expiry === "72hours" ? 1 : expiry === "1year" ? 2 : 0,
          })
        );
        toast.success(
          `${intl.formatMessage(
            { id: "PERSON_ADDED" },
            { name: `${firstName} ${lastName}` }
          )}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        clearState();
        return;
      } else {
        dispatch(
          addRegistrant({
            firstName,
            lastName,
            dateOfBirth,
            documentType,
            docNo,
            docExpiryDate,
            phoneNumber,
            companyName,
            expiry: expiry === "72hours" ? 1 : expiry === "1year" ? 2 : 0,
          })
        );
        dispatch(changeProcessStep(3));
      }
    } else {
      dispatch(setLoading(false));
      dispatch(
        setError({
          status: true,
          errorMessage: intl.formatMessage({ id: "PERSONAL_DATA_NEXT_ERROR" }),
        })
      );
    }
  };

  const handlePrevious = () => {
    dispatch(resetProcess());
    dispatch(changeProcessStep(1));
  };

  const checkButtonDisabled = () => {
    if (loading) return true;
    if (!expiry || expiry === "0") return true;
    if (registrationType === "multiple" && registeredPersons.length >= 5)
      return true;
    if (
      !docExpiryDate?.isValid() ||
      !docExpiryDate?.toDate() ||
      docExpiryDate.toDate().getTime() <= new Date().getTime()
    )
      return true;
    if (
      firstName.length &&
      lastName.length &&
      docNo.length &&
      documentType.length
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      {previousPageModal && (
        <PreviousPageModal
          closeModal={() => setPreviousPageModal(false)}
          onProceed={handlePrevious}
        />
      )}
      <DataProtection
        expiry={expiry}
        setExpiry={(val) => dispatch(setExpiry(val))}
        setError={(val) => dispatch(setError(val))}
      />
      <FormPage />
      <ButtonContainer>
        <Button
          isSolid
          style={{ marginRight: "1em" }}
          onClick={() => setPreviousPageModal(true)}
        >
          <ArrowLeftImg src={RightArrowSmBlue} alt="right-arrow-blue" />
          {intl.formatMessage({ id: "PREVIOUS" })}
        </Button>
        <Button onClick={handleClickNext} disabled={checkButtonDisabled()}>
          {intl.formatMessage({
            id:
              registrationType === "single"
                ? "NEXT"
                : editRegistrantId
                ? "UPDATE_THE_PERSON"
                : "ADD_THE_PERSON",
          })}
          <ArrowImg src={RightArrowSm} alt="right-arrow" />
        </Button>
      </ButtonContainer>
    </>
  );
}

export default SecondPage;
