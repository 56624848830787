import React from "react";
import Button from "./Button";
import RightArrowSmBlue from "../assets/right-arrow-sm-blue.svg";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import styled from "styled-components";
import { useIntl } from "react-intl";
import warningIcon from "../assets/warningIcon.svg";
import companyIcon from "../assets/companyIcon.svg";

const Background = styled.div`
  background: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalInner = styled.div`
  border-radius: 18px;
  background: #fff;
  position: relative;
  max-width: 40vw;
  padding: 50px;
  z-index: 9999;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderText = styled.h1`
  font-size: 20px;
  font-weight: 600;
  color: #ff9500;
  margin-left: 12px;
`;

const ModalText = styled.p`
  margin: 12px 0;
  font-weight: 400;
  font-size: 16px;
  color: #80808f;
  & > b {
    color: #000;
  }
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const BoldText = styled.h4`
  font-weight: 600;
  font-size: 20px;
  color: #464e5f;
`;

const RegistrantContainer = styled.div`
  padding: 10px 16px;
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-bottom: 16px;
  & > h4 {
    font-weight: 600;
    font-size: 18px;
    color: #2f3044;
  }
  & > div {
    display: flex;
    & > img {
      margin-right: 8px;
    }
    & > span {
      font-weight: 400;
      font-size: 16px;
      color: #848484;
    }
  }
`;

function VideoConfirmWarning({ closeModal, registrants, onConfirm }) {
  const intl = useIntl();
  return (
    <Background>
      <ModalInner>
        <Header>
          <img src={warningIcon} alt="warning" />
          <HeaderText>{intl.formatMessage({ id: "WARNING" })}</HeaderText>
        </Header>

        <BoldText>
          {intl.formatMessage({ id: "ARE_YOU_SURE_TO_FINISH" })}
        </BoldText>
        <ModalText
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: "VIDEO_CONFIRM_MODAL_DESC" }),
          }}
        />

        {registrants.map((registrant, index) => (
          <RegistrantContainer key={index}>
            <h4>{`${registrant.firstName} ${registrant.lastName}`}</h4>
            {registrant.companyName && (
              <div>
                <img src={companyIcon} alt="company" />
                <span>{registrant.companyName}</span>
              </div>
            )}
          </RegistrantContainer>
        ))}

        <ButtonContainer>
          <Button isSolid onClick={closeModal} style={{ fontSize: 16 }}>
            {intl.formatMessage({ id: "CANCEL" })}
            <ArrowImg src={RightArrowSmBlue} alt="right-arrow" />
          </Button>
          <Button onClick={onConfirm} style={{ marginLeft: 16, fontSize: 16 }}>
            {intl.formatMessage({ id: "ACCEPT_AND_FINISH" })}
            <ArrowImg src={RightArrowSm} alt="right-arrow" />
          </Button>
        </ButtonContainer>
      </ModalInner>
    </Background>
  );
}

export default VideoConfirmWarning;
