import dayjs from "dayjs";
import {
  ADD_REGISTRANT,
  CHANGE_REGISTRANT_VIDEO_STATUS,
  CHANGE_REGISTRATION_TYPE,
  CHANGE_STEP,
  EDIT_REGISTRANT,
  REMOVE_REGISTRANT,
  RESET_PROCESS,
  SET_VIDEO_WATCHED,
  UPDATE_REGISTRANT,
  FIRST_NAME,
  LAST_NAME,
  DATE_OF_BIRTH,
  DOCUMENT_TYPE,
  DOC_NO,
  DOC_EXPIRY_DATE,
  COMPANY_NAME,
  PHONE_NUMBER,
  EXPIRY,
  ERROR,
} from "./actions";

const INITIAL_STATE = {
  step: 0,
  registrationType: "",
  registeredPersons: [],
  editRegistrantId: null,
  isVideoWatched: false,
  loading: false,
  firstName: "",
  lastName: "",
  dateOfBirth: dayjs(""),
  documentType: "",
  docNo: "",
  docExpiryDate: dayjs(""),
  companyName: "",
  phoneNumber: "",
  expiry: "",
  error: { status: false, errorMessage: "" },
};

const formReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    case CHANGE_REGISTRATION_TYPE:
      return {
        ...state,
        registrationType: action.payload.registrationType,
      };
    case ADD_REGISTRANT:
      return {
        ...state,
        registeredPersons: [
          ...state.registeredPersons,
          action.payload.registrant,
        ],
      };
    case REMOVE_REGISTRANT:
      return {
        ...state,
        registeredPersons: state.registeredPersons.filter(
          (person) => person.docNo !== action.payload.registrantDocNo
        ),
      };
    case EDIT_REGISTRANT:
      return {
        ...state,
        editRegistrantId: action.payload.registrantDocNo,
      };
    case UPDATE_REGISTRANT:
      return {
        ...state,
        registeredPersons: state.registeredPersons.map((person) => {
          if (person.docNo === state.editRegistrantId) {
            return action.payload.registrant;
          }
          return person;
        }),
      };
    case SET_VIDEO_WATCHED:
      return {
        ...state,
        isVideoWatched: true,
      };
    case CHANGE_REGISTRANT_VIDEO_STATUS:
      return {
        ...state,
        registeredPersons: state.registeredPersons.map((person) => {
          if (person.docNo === action.payload.registrantDocNo) {
            return {
              ...person,
              videoStatus: action.payload.videoStatus,
            };
          }
          return person;
        }),
      };
    case RESET_PROCESS:
      return {
        ...INITIAL_STATE,
        step: state.step,
      };
    case FIRST_NAME:
      return {
        ...state,
        firstName: action.payload.firstName,
      };
    case LAST_NAME:
      return {
        ...state,
        lastName: action.payload.lastName,
      };
    case DATE_OF_BIRTH:
      return {
        ...state,
        dateOfBirth: action.payload.dateOfBirth,
      };
    case DOCUMENT_TYPE:
      return {
        ...state,
        documentType: action.payload.documentType,
      };
    case DOC_NO:
      return {
        ...state,
        docNo: action.payload.docNo,
      };
    case DOC_EXPIRY_DATE:
      return {
        ...state,
        docExpiryDate: action.payload.docExpiryDate,
      };
    case COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload.companyName,
      };
    case PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      };
    case EXPIRY:
      return {
        ...state,
        expiry: action.payload.expiry,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default formReducer;
