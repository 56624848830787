import React from "react";
import { IntlProvider } from "react-intl";

import enMessages from "./locales/en";
import huMessages from "./locales/hu";
import deMessages from "./locales/de";
import { useSelector } from "react-redux";

const allMessages = {
  en: enMessages,
  hu: huMessages,
  de: deMessages,
};

export function I18nProvider({ children }) {
  const { selectedLang: locale } = useSelector((state) => state.user);
  return (
    <IntlProvider locale={locale} messages={allMessages[locale]}>
      {children}
    </IntlProvider>
  );
}
