import React from "react";
import timesIcon from "../assets/timesIcon.svg";
import styled from "styled-components";
import { useSelector } from "react-redux";

const Background = styled.div`
  background: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalInner = styled.div`
  border-radius: 18px;
  background: #fff;
  position: relative;
  min-width: 40vw;
  max-width: 40vw;
  padding: 50px;
  min-height: 70vh;
  max-height: 80vh;
  overflow: auto;
`;

const TimesIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
`;

const Links = {
  hu: "https://www.knorr-bremse.hu/media/documents/railvehicles/downloads/beleptetes_adatkezelesi_tajekoztato/Belptets_adatkezelsi_tjkoztat_nyilatkozat_nlkuel_HUN.pdf",
  de: "https://www.knorr-bremse.hu/media/documents/railvehicles/downloads/beleptetes_adatkezelesi_tajekoztato/Belptets_adatkezelsi_tjkoztat_nyilatkozat_nlkueli_de.pdf",
  en: "https://www.knorr-bremse.hu/media/documents/railvehicles/downloads/beleptetes_adatkezelesi_tajekoztato/Belptets_adatkezelsi_tjkoztat_nyilatkozat_nlkueli_eng.pdf",
};

function PdfModal({ closeModal }) {
  const { selectedLang } = useSelector((state) => state.user);
  return (
    <Background>
      <ModalInner>
        <TimesIcon src={timesIcon} alt="times-icon" onClick={closeModal} />
        <iframe
          src={`${Links[selectedLang]}#toolbar=0&navpanes=0&scrollbar=0`}
          height="700"
          width="100%"
        ></iframe>
      </ModalInner>
    </Background>
  );
}

export default PdfModal;
