import React, { useState } from "react";
import Success from "../assets/success.svg";
import styled from "styled-components";
import Button from "../components/Button";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import { useDispatch } from "react-redux";
import {
  changeProcessStep,
  resetProcess,
  setCompanyName,
  setDateOfBirth,
  setDocExpiryDate,
  setDocNo,
  setDocumentType,
  setError,
  setExpiry,
  setFirstName,
  setLastName,
  setPhoneNumber,
} from "../redux/actions";
import useInterval from "../hooks/useInterval";
import { useIntl } from "react-intl";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
`;

const HeaderText = styled.h3`
  font-size: 30px;
  font-weight: 600;
  color: #2f3044;
  margin: 1em 0;
`;

const HeaderDesc = styled.p`
  padding: 0 12em;
  font-size: 20px;
  font-weight: 500;
  color: #848484;
  text-align: center;
  white-space: break-spaces;
  & > b {
    font-size: 20px;
    font-weight: 600;
    color: #212121;
  }

  @media only screen and (max-width: 1350px) {
    padding: 0 4em;
  }
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const RedirectText = styled.p`
  margin: 2em 0;
  padding: 0 12em;
  font-size: 20px;
  font-weight: 500;
  color: #848484;
  text-align: center;

  & > b {
    color: #0f0f84;
  }

  @media only screen and (max-width: 1350px) {
    padding: 0 4em;
  }
`;

function FinalPage() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [leftSecs, setLeftSecs] = useState(7);
  const handleResetProcess = () => {
    dispatch(resetProcess());
    dispatch(changeProcessStep(0));
  };
  useInterval(() => {
    if (leftSecs > 0) {
      let newSec = leftSecs - 1;
      if (newSec > 0) {
        setLeftSecs(newSec);
      } else {
        setLeftSecs(0);
        handleResetProcess();
      }
    }
  }, 1000);
  return (
    <Container>
      <img src={Success} alt="success" />
      <HeaderText>{intl.formatMessage({ id: "SUCCESS_HEADER" })}</HeaderText>
      <HeaderDesc
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: "SUCCESS_DESC" }),
        }}
      />
      <Button onClick={handleResetProcess} style={{ marginTop: "5em" }}>
        {intl.formatMessage({ id: "NEW_CHECK_IN" })}
        <ArrowImg src={RightArrowSm} alt="right-arrow" />
      </Button>
      <RedirectText>
        {intl.formatMessage({ id: "SUCCESSFUL_MESSAGE" }, { leftSecs })}
      </RedirectText>
    </Container>
  );
}

export default FinalPage;
