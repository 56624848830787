import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #2f3044;
  margin-bottom: 8px;
`;

const Input = styled.input`
  background: #f3f6f9;
  border-radius: 9px;
  padding: 23px 22px 23px 22px;
  outline: none;
  border: none;
`;

const ErrorText = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: #ff0000;
  margin-bottom: 8px;
`;

function LoginInput({ label, style, value, onChange, error, type }) {
  return (
    <Container style={style}>
      <Label>{label}</Label>
      <Input value={value} onChange={onChange} type={type} />
      {error.type === label && <ErrorText>{error.errorMessage}</ErrorText>}
    </Container>
  );
}

export default LoginInput;
