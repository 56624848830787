import React, { useState } from "react";
import styled from "styled-components";
import protectIcon from "../assets/protectIcon.svg";
import editIcon from "../assets/registrantEdit.svg";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { changeRegistrantVideoStatus, editRegistrant } from "../redux/actions";
import companyIcon from "../assets/companyIcon.svg";
import checkboxRed from "../assets/checkboxRed.svg";
import checkboxRedChecked from "../assets/checkboxRedChecked.svg";
import checkboxGreen from "../assets/checkboxGreen.svg";
import checkboxGreenChecked from "../assets/checkboxGreenChecked.svg";

const Container = styled.div`
  background: #fff;
  margin-bottom: 16px;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
`;

const Name = styled.h4`
  font-weight: 600;
  font-size: 18px;
  color: #2f3044;
  margin-bottom: 8px;
`;

const LineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > * {
    width: 50%;
  }
`;

const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  & > img {
    margin-right: 8px;
  }
`;

const CompanyName = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: #848484;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  opacity: ${(props) => (props.isVideoWatched ? 1 : 0.5)};
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  & > img {
    cursor: pointer;
    margin-right: 4px;
  }
  &:last-child {
    margin-left: 16px;
  }
`;

function Registrant({ registrant }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { isVideoWatched } = useSelector((state) => state.form);

  const handleCheckbox = (type) => {
    if(!isVideoWatched) return;
    dispatch(changeRegistrantVideoStatus(registrant.docNo, type));
  };

  return (
    <Container>
      <LineContainer>
        <Name>{`${registrant.firstName} ${registrant.lastName}`}</Name>
        <CompanyName>{intl.formatMessage({ id: "VIDEO_TERMS" })}</CompanyName>
      </LineContainer>
      <LineContainer>
        {registrant.companyName ? (
          <CompanyContainer>
            <img src={companyIcon} alt="company" height={24} width={24} />
            <CompanyName>{registrant.companyName}</CompanyName>
          </CompanyContainer>
        ) : (
          <div></div>
        )}
        <CheckboxContainer isVideoWatched={isVideoWatched}>
          <Checkbox>
            <img
              onClick={() => handleCheckbox("accept")}
              src={registrant?.videoStatus === "accept" ? checkboxGreenChecked : checkboxGreen}
              alt="green-checkbox"
            />
            {intl.formatMessage({ id: "ACCEPT" })}
          </Checkbox>
          <Checkbox>
            <img
              onClick={() => handleCheckbox("reject")}
              src={registrant?.videoStatus === "reject" ? checkboxRedChecked : checkboxRed}
              alt="red-checkbox"
            />
            {intl.formatMessage({ id: "REJECT" })}
          </Checkbox>
        </CheckboxContainer>
      </LineContainer>
    </Container>
  );
}

export default Registrant;
