import React, { useState } from "react";
import styled from "styled-components";
import LoginInput from "./LoginInput";
import RightIcon from "../../assets/right-arrow.svg";
import { useDispatch } from "react-redux";
import { login } from "../../redux/actions";
import { authLogin } from "../../redux/Api";
import { useIntl } from "react-intl";

const Container = styled.div`
  height: 81vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 48px;
`;

const LoginFormArea = styled.form`
  background: #fff;
  border-radius: 30px;
  width: 50vw;
  padding: 36px;
  @media only screen and (max-width: 992px) {
    width: 70vw;
    padding: 30px;
  }
`;

const FormHeader = styled.h1`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  color: #2f3044;
`;

const FormHeaderDesc = styled.h6`
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #a7a8bb;
  margin-bottom: 48px;
`;

const LoginButton = styled.button`
  background: #004481;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  margin-top: 36px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
`;

function LoginForm() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    type: "",
    errorMessage: "",
  });

  const handleLogin = (e) => {
    e.preventDefault();
    if (!username.length) {
      setError({
        type: "Username",
        errorMessage: intl.formatMessage({ id: "USERNAME_ERROR" }),
      });
      return;
    }
    if (!password.length) {
      setError({
        type: "Password",
        errorMessage: intl.formatMessage({ id: "PASSWORD_ERROR" }),
      });
      return;
    }
    authLogin(username, password)
      .then(({ data }) => {
        dispatch(
          login(username, password, data.accessToken, data.refreshToken)
        );
      })
      .catch((err) => {
        if (err?.response?.data?.errorMessage) {
          setError({
            type: "Password",
            errorMessage: err.response.data.errorMessage,
          });
        }
      });
  };

  return (
    <Container>
      <LoginFormArea onSubmit={handleLogin}>
        <FormHeader>{intl.formatMessage({ id: "LOGIN_HEADER" })}</FormHeader>
        <FormHeaderDesc>
          {intl.formatMessage({ id: "LOGIN_HEADER_DESC" })}
        </FormHeaderDesc>
        <LoginInput
          label={intl.formatMessage({ id: "USERNAME" })}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          error={error}
          type={"text"}
          name={"username"}
        />
        <LoginInput
          label={intl.formatMessage({ id: "PASSWORD" })}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: 30 }}
          error={error}
          type={"password"}
          name={"password"}
        />
        <LoginButton type={"submit"}>
          {intl.formatMessage({ id: "LOGIN_BUTTON_TITLE" })}{" "}
          <img src={RightIcon} alt="right-icon" />
        </LoginButton>
      </LoginFormArea>
    </Container>
  );
}

export default LoginForm;
