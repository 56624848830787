export const LOGIN = "USER_LOGIN";
export const LOGOUT = "USER_LOGOUT";
export const CHANGE_ACCESS_TOKEN = "CHANGE_ACCESS_TOKEN";
export const CHANGE_STEP = "CHANGE_STEP";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_REGISTRATION_TYPE = "CHANGE_REGISTRATION_TYPE";
export const ADD_REGISTRANT = "ADD_REGISTRANT";
export const REMOVE_REGISTRANT = "REMOVE_REGISTRANT";
export const EDIT_REGISTRANT = "EDIT_REGISTRANT";
export const UPDATE_REGISTRANT = "UPDATE_REGISTRANT";
export const SET_VIDEO_WATCHED = "SET_VIDEO_WATCHED";
export const CHANGE_REGISTRANT_VIDEO_STATUS = "CHANGE_REGISTRANT_VIDEO_STATUS";
export const RESET_PROCESS = "RESET_PROCESS";
export const FIRST_NAME = "FIRST_NAME";
export const LAST_NAME = "LAST_NAME";
export const DATE_OF_BIRTH = "DATE_OF_BIRTH";
export const DOCUMENT_TYPE = "DOCUMENT_TYPE";
export const DOC_NO = "DOC_NO";
export const DOC_EXPIRY_DATE = "DOC_EXPIRY_DATE";
export const COMPANY_NAME = "COMPANY_NAME";
export const PHONE_NUMBER = "PHONE_NUMBER";
export const EXPIRY = "EXPIRY";
export const LOADING = "LOADING";
export const ERROR = "ERROR";

export const login = (username, password, accessToken, refreshToken) => ({
  type: LOGIN,
  payload: {
    username,
    password,
    accessToken,
    refreshToken,
  },
});

export const logout = () => ({
  type: LOGOUT,
});

export const changeAccessToken = (newAccessToken) => ({
  type: CHANGE_ACCESS_TOKEN,
  payload: {
    newAccessToken,
  },
});

export const changeProcessStep = (step) => ({
  type: CHANGE_STEP,
  payload: {
    step,
  },
});

export const changeLanguage = (lang) => ({
  type: CHANGE_LANGUAGE,
  payload: {
    lang,
  },
});

export const changeRegistrationType = (registrationType) => ({
  type: CHANGE_REGISTRATION_TYPE,
  payload: {
    registrationType,
  },
});

export const addRegistrant = (registrant) => ({
  type: ADD_REGISTRANT,
  payload: {
    registrant,
  },
});

export const removeRegistrant = (registrantDocNo) => {
  return {
    type: REMOVE_REGISTRANT,
    payload: {
      registrantDocNo,
    },
  };
};

export const editRegistrant = (registrantDocNo) => ({
  type: EDIT_REGISTRANT,
  payload: {
    registrantDocNo,
  },
});

export const updateRegistrant = (registrant) => ({
  type: UPDATE_REGISTRANT,
  payload: {
    registrant,
  },
});

export const setVideoWatched = () => ({
  type: SET_VIDEO_WATCHED,
});

export const changeRegistrantVideoStatus = (registrantDocNo, videoStatus) => ({
  type: CHANGE_REGISTRANT_VIDEO_STATUS,
  payload: {
    videoStatus,
    registrantDocNo,
  },
});

export const resetProcess = () => ({
  type: RESET_PROCESS,
});

export const setFirstName = (firstName) => ({
  type: FIRST_NAME,
  payload: {
    firstName,
  },
});

export const setLastName = (lastName) => ({
  type: LAST_NAME,
  payload: {
    lastName,
  },
});

export const setDateOfBirth = (dateOfBirth) => ({
  type: DATE_OF_BIRTH,
  payload: {
    dateOfBirth,
  },
});

export const setDocumentType = (documentType) => ({
  type: DOCUMENT_TYPE,
  payload: {
    documentType,
  },
});

export const setDocNo = (docNo) => ({
  type: DOC_NO,
  payload: {
    docNo,
  },
});

export const setDocExpiryDate = (docExpiryDate) => ({
  type: DOC_EXPIRY_DATE,
  payload: {
    docExpiryDate,
  },
});

export const setCompanyName = (companyName) => ({
  type: COMPANY_NAME,
  payload: {
    companyName,
  },
});

export const setPhoneNumber = (phoneNumber) => ({
  type: PHONE_NUMBER,
  payload: {
    phoneNumber,
  },
});

export const setExpiry = (expiry) => ({
  type: EXPIRY,
  payload: {
    expiry,
  },
});

export const setLoading = (loading) => ({
  type: LOADING,
  payload: {
    loading,
  },
});

export const setError = (error) => ({
  type: ERROR,
  payload: {
    error,
  },
});
