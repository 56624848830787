import React from "react";
import Button from "./Button";
import RightArrowSmBlue from "../assets/right-arrow-sm-blue.svg";
import RightArrowSm from "../assets/right-arrow-sm.svg";
import styled from "styled-components";
import { useIntl } from "react-intl";

const Background = styled.div`
  background: rgba(33, 33, 33, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalInner = styled.div`
  border-radius: 18px;
  background: #fff;
  position: relative;
  max-width: 30vw;
  padding: 50px;
  z-index: 9999;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #464e5f;
`;

const ModalText = styled.p`
  font-size: 20px;
  font-weight: 400;
  color: #80808f;
  margin: 20px 0;
`;

const ArrowImg = styled.img`
  margin: auto 0 auto 0.5em;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

function DeleteRegistrantConfirmModal({ closeModal, deleteRegistrant }) {
  const intl = useIntl();
  return (
    <Background>
      <ModalInner>
        <Header>
          <HeaderText>{intl.formatMessage({ id: "ARE_YOU_SURE" })}</HeaderText>
        </Header>
        <ModalText>
          {intl.formatMessage({ id: "DELETE_REGISTRANT_DESC" })}
        </ModalText>
        <ButtonContainer>
          <Button isSolid onClick={closeModal}>
            {intl.formatMessage({ id: "QUIT" })}
            <ArrowImg src={RightArrowSmBlue} alt="right-arrow" />
          </Button>
          <Button onClick={deleteRegistrant} style={{ marginLeft: 16 }}>
            {intl.formatMessage({ id: "DELETE" })}
            <ArrowImg src={RightArrowSm} alt="right-arrow" />
          </Button>
        </ButtonContainer>
      </ModalInner>
    </Background>
  );
}

export default DeleteRegistrantConfirmModal;
