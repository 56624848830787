import { CHANGE_ACCESS_TOKEN, CHANGE_LANGUAGE, LOGIN, LOGOUT } from "./actions";

const INITIAL_STATE = {
  username: "",
  password: "",
  selectedLang: "en",
  token: "",
  refreshToken: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        token: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };
    case LOGOUT:
      return INITIAL_STATE;
    case CHANGE_ACCESS_TOKEN:
      return {
        ...state,
        token: action.payload.newAccessToken,
      };
    case CHANGE_LANGUAGE:
      return {
        ...state,
        selectedLang: action.payload.lang,
      };
    default:
      return state;
  }
};

export default userReducer;
